.intro-container {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  flex-wrap: nowrap;
  background-color: rgb(2,191,179);
}

.intro-section {
  min-width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  position: relative;
}

/* .contrast-text {
  mix-blend-mode: difference;
} */

.left-align {
  justify-content: flex-start;
}

.right-align {
  justify-content: flex-end;
}

/* Media query for window width less than 735px */
@media (max-width: 735px) {
  .intro-section:first-child {
    background-position: left;
  }

  .intro-section:nth-child(3) {
    background-position: right;
  }
}