.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,body{
  overflow-x: hidden;
}

.bg_image{
  background-image:url('./component//backgroundImage/XWcqY75NywIBJis.png');
  background-size:100%;
  background-repeat: repeat;
}

.gradient_limeade{
  background: linear-gradient(to right, #a1ffce, #faffd1)
}

.gradient_limeade_merchant{
  background: linear-gradient(to bottom, #faffd1, #a1ffce)
}

.afrogirl{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1653725578/Screen_Shot_2022_05_28_at_4_12_27_PM_267683e89e.png?212081.29999999702');
  background-size: cover;
  background-position: left;
}

.womenbodies{
  background-image:url("https://res.cloudinary.com/sheyou/image/upload/v1654170478/body_positive_three_attractive_multicultural_wome_2021_08_29_04_54_43_utc_31e9d52795.jpg");
}

.womensitting{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1654171401/cheerful_multiethnic_women_sitting_on_pier_2022_05_31_06_57_17_utc_2ec9b28908.jpg');
 
}

.redhatright{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1653744940/Screen_Shot_2022_05_28_at_9_35_28_PM_f8c0e1a21f.png');
}

.fashionwastes{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1653730579/Screen_Shot_2022_05_28_at_5_35_56_PM_a977be6fbc.png?1774877.1000000015')
}

.verticalDivider{
  border-right: 1.5px solid rgba(0,0,0,0.1);
  height: 200px;  
}
.verticalDividerNav{
  border-right: 1.5px solid rgba(0,0,0,0.1);
   
}

.bottomDivider{
  border-bottom: 1.5px solid rgba(255,255,255,0.1);
}

.headphonegirl{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1653724863/woman_in_red_hat_sunglasses_and_suit_of_90s_with_2022_01_12_03_20_40_utc_7cb0c2bedc.jpg?291171.1000000015  ');
 
}


.warehouse{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1653733448/Screen_Shot_2022_05_28_at_6_23_46_PM_f3d08f7d9e.png?4640704.6000000015');
  background-size:100%;
}

.bg_color_image{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1645963256/IMG_2342_06609c160e.jpg?167986.89999997616');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_color_image_orange_green{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1645967944/IMG_2342_665bd36708.jpg?43405.69999998808');
  background-size:100%;
}

.bg_color_image_green_orange{
  background-image:url('https://res.cloudinary.com/sheyou/image/upload/v1645968118/IMG_2342_294c65c475.jpg?260754.69999998808');
  background-size:100%;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.swiper-container {
  width: 200px;
  height: 300px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-height-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.half-height-section {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quarter-height-section{
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* @media (max-width: 780px) {
  .intro-section {
    background-position: left;
  }
} */

.home-section {
  background-image: url('https://res.cloudinary.com/sheyou/image/upload/v1654168716/positive_diverse_women_enjoying_time_on_sandy_coas_2022_01_17_19_08_29_utc_0ba4f60e0c.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* @media (max-width: 780px) {
  .home-section {
    background-position: right;
  }
} */

.scroll-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scroll-content {
  width: 100vh *(3840/1080); /* Adjust this to match your image width */
  height: 100vh;
  display: flex;
}

.image {
  width: 100vh *(3840/1080); /* Adjust this to match your image width */
  height: 100vh;
  object-fit: cover;
}

.long-image {
  height: 100%;
  width: auto;
}

.ui.segment {
  background-color: #F5F5DD;
}

/* .ui.button {
  background-color: #F5F5DD;
} */
