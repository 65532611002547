.occasion-container {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: none;
  flex-wrap: nowrap;
  background-color: rgb(2,191,179);
}

.occasion-section {
  min-width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  position: relative;
}

.contrast-text {
  mix-blend-mode: difference;
}

.static-container {
  position: relative;
  z-index: 1000;
}
